import {MenuOutlined} from "@ant-design/icons";
import styles from "./mobileMenu.module.less"
import {Dropdown, Menu, Select, Typography} from "antd";
import {Link} from "react-router-dom";
import {AsyncButton} from "digimuth-components/dist";
import React, { useEffect, useState } from "react";
import {useSelector} from "react-redux";
import {getCurrentUser} from "../../redux/applicationUserSlice";
import ProfilePicture from "../profilePicture/profilePicture";
import {RoutingPaths} from "../../pages/routing/routingContainer";
import useCurrentOrganisation from "../../hooks/useCurrentOrganisation";
import { useInjection } from "inversify-react";
import ApiService from "../../services/apiService";
import { getOrganisations } from "../../redux/organisationSlice";

export default function MobileMenu(props: MobileMenuComponentProps) {
    const organisations = useSelector(getOrganisations);
    const { organisationId, setOrganisationId } = useCurrentOrganisation();
    const currentUser = useSelector(getCurrentUser);

    const menu = (
            <Menu
            style={{height: "calc(100vh - 81px)" ,textAlign: "center", marginTop: "0.75rem"}}
            items={[{
                label: currentUser && <Link to={RoutingPaths.yourProfile.route} className={styles.userInfo}>
                    <ProfilePicture profilePictureId={currentUser.profileImageId} className={styles.avatar}/>
                    <div className={styles.userProfile}>
                        <div className={styles.userName}>
                            {`${currentUser.firstName} ${currentUser.lastName}`}
                        </div>
                        <div className={styles.userEdit}>
                            Twój profil
                        </div>
                    </div>
                </Link>,
                key: '3',
            },
                {
                    label: <div className={styles.menuItem}><Link onClick={props.onNotificationsPressed} to={""}>Powiadomienia</Link></div>,
                    key: '0',
                },
                {
                    label: <div className={styles.menuItem}><Link to={RoutingPaths.calendar.route}>Kalendarz</Link></div>,
                    key: '1',
                },
                {
                    label: (organisations?.length || 0) > 1 && <Typography.Text ellipsis={true} className={styles.organisationSelectorLabel} type="secondary">Organizacja</Typography.Text>,
                    type: "group",
                    children: (organisations?.length || 0) > 1 && organisations?.map(o => ({
                        label: <div className={o.id === organisationId ? styles.selectedOrganisation : styles.organisation}>{o.name}</div>,
                        onClick: () => setOrganisationId(o.id!),
                        key: o.name
                    })),
                    key: '2'
                },
                {
                    label: <div className={styles.logoutButton}><AsyncButton
                        onClick={props.onLogoutPressed}>Wyloguj</AsyncButton></div>,
                    key: '4',
                }
            ]}
        />
    );

    return <div className={styles.mobileMenuWrapper}>
        <Dropdown overlay={menu} trigger={['click']} overlayClassName={styles.dropdownWrapper} placement={"topLeft"}>
            <MenuOutlined className={styles.icon}/>
        </Dropdown>
    </div>
}

interface MobileMenuComponentProps {
    onLogoutPressed: () => Promise<any>,
    onNotificationsPressed: () => any
}
