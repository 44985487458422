import { useAuth } from "oidc-react";
import { useNavigate } from "react-router-dom";
import { RoutingPaths } from "../../pages/routing/routingContainer";
import HeaderComponent from "./header";

export default function HeaderContainer() {
    const auth = useAuth();
    const navigate = useNavigate();

    const onLogoutPressed = async () => {
        await auth.userManager.clearStaleState();
        await auth.signOut();
        await auth.signOutRedirect();
    }

    const onLoginPressed = async () => {
        navigate(RoutingPaths.login.route);
    }

    return <HeaderComponent
        onLogoutPressed={onLogoutPressed}
        userData={auth.userData}
        onLoginPressed={onLoginPressed}
    />;
}
