import {createSlice} from "@reduxjs/toolkit";
import {NotificationDto} from "../api";

const initialState: { notifications: NotificationDto[], latestNotification: NotificationDto | null } = {
    notifications: [],
    latestNotification: null
};

const notificationSlice = createSlice({
    name: 'notificationSlice',
    initialState,
    reducers: {
        setNotificationsData: (state, action) => {
            state.notifications = action.payload;
        },
        setLatestNotification: (state, action) => {
            state.latestNotification = action.payload;
        }
    }
});

// these methods only modify the state
export const {setNotificationsData, setLatestNotification} = notificationSlice.actions;

// these methods only read the state
export const getNotifications = (state: any) => state.notificationSlice.notifications as NotificationDto[];
export const getLatestNotification = (state: any) => state.notificationSlice.latestNotification as NotificationDto;

export default notificationSlice.reducer;
