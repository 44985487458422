import { FormOutlined, TableOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import styles from "./siderMenu.module.scss"
import { useCurrentRoutingPath } from "digimuth-components/dist";
import { RoutingPaths } from "../../pages/routing/routingContainer";

export default function SiderMenu() {
  const route = useCurrentRoutingPath(RoutingPaths)?.route || "";

  return <>
    <NavLink to={"/"}>
      <h3 className={styles.logo}>Logo</h3>
    </NavLink>
    <Menu theme="dark" selectedKeys={[route]} mode="inline">
      <Menu.Item key={RoutingPaths.crudTable.route} icon={<TableOutlined />}>
        <NavLink to={RoutingPaths.crudTable.route}>
          Crud Table
        </NavLink>
      </Menu.Item>
      <Menu.Item key={RoutingPaths.crudForm.route} icon={<FormOutlined />}>
        <NavLink to={RoutingPaths.crudForm.route}>
          Crud Form
        </NavLink>
      </Menu.Item>
    </Menu>
  </>;
}