import axios, { AxiosError, AxiosResponse } from "axios";
import { injectable } from "inversify";
import "reflect-metadata";
import { Configuration } from "../api";
import { BaseAPI } from "../api/base";
import { userManager } from "../App";
import { message } from "antd";
import React from "react";
import { getOrganisationId } from "../hooks/useCurrentOrganisation";

@injectable()
export default class ApiService {

    private displaying401Error: boolean = false;
    private displaying403Error: boolean = false;
    private displayingUnknownError: boolean = false;

    constructor() {
        const me = this;

        axios.interceptors.response.use(
            (response: AxiosResponse) => {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                return response;
            },
            function (error: AxiosError) {
                if (error.response?.status === 401) {
                    return userManager?.signinSilent().then(u => {
                        error.config.headers!["Authorization"] = `Bearer ${u.access_token}`;

                        return axios.request(error.config);
                    }).catch(() => {
                        if (!me.displaying401Error) {
                            me.displaying401Error = true;
    
                            message.warning(<>
                                <h3>Sesja wygasła</h3>
                                <div>Następuje przekierowanie do strony logowania.</div>
                            </>);
    
                            setTimeout(() => me.displaying401Error = false, 3000);
                        }
                        userManager?.removeUser().then(() => 
                            userManager?.signinRedirect({ state: { targetPath: window.location.pathname } })
                        );
                    });
                } else if (error.response?.status === 403) {

                    if (!me.displaying403Error) {
                        me.displaying403Error = true;

                        message.warning(<>
                            <h3>Wygląda na to że nie masz dostępu do żądanego zasobu</h3>
                            <div>Spróbuj zalogować się ponownie. Jeżeli to nie pomoże skontaktuj się z administratorem</div>
                        </>);

                        setTimeout(() => me.displaying403Error = false, 3000);
                    }
                } else if (error.response?.status !== 400 && error.response?.status !== 404 && error.message !== "canceled") {

                    if (!me.displayingUnknownError) {
                        me.displayingUnknownError = true;

                        message.error(<>
                            <h3>Wystąpił błąd</h3>
                            <div>Wystąpił błąd. Skontaktuj się z administratorem.</div>
                        </>);

                        setTimeout(() => me.displayingUnknownError = false, 3000);
                    }
                }
                return Promise.reject(error);
            }
        );

        axios.defaults.withCredentials = true;
    }

    public getApi<T extends BaseAPI>(c: new (configuration?: Configuration, basePath?: string) => T): T {
        const apiUrl = process.env.REACT_APP_URL;
        const session = sessionStorage.getItem(`oidc.user:${apiUrl}:Digimuth.GwExperts.Web`);
        const accessToken = session ? JSON.parse(session)["access_token"] : null;
        const baseOptions = {
            headers: {
                "Organisation": getOrganisationId()
            }
        };

        return new c(new Configuration({ accessToken, baseOptions }), apiUrl);
    }

    public getApiWithOrganisation<T extends BaseAPI>(c: new (configuration?: Configuration, basePath?: string) => T, organisationId: number): T {
        const apiUrl = process.env.REACT_APP_URL;
        const session = sessionStorage.getItem(`oidc.user:${apiUrl}:Digimuth.GwExperts.Web`);
        const accessToken = session ? JSON.parse(session)["access_token"] : null;
        const baseOptions = {
            headers: {
                "Organisation": organisationId
            }
        };

        return new c(new Configuration({ accessToken, baseOptions }), apiUrl);
    }
}