import { createSlice } from "@reduxjs/toolkit";
import { ApplicationUserRegisterDto } from "../api";
import moment from "moment";

const initialState: { user: ApplicationUserRegisterDto } = {
    user: {
        id: 0,
        firstName: "",
        lastName: "",
        birthDate: null,
        professionId: null,

        phoneNumber: "",
        email: "",
        addressOfResidence: {
            street: "",
            houseNumber: "",
            flatNumber: "",
            postalCode: "",
            city: ""
        },
        mailingAddress: {
            street: "",
            houseNumber: "",
            flatNumber: "",
            postalCode: "",
            city: ""
        },

        gwExpertsCooperationBeginDate: moment().toISOString(),
        profileImageId: null
    }
};

const userWizardSlice = createSlice({
    name: 'userWizardSlice',
    initialState,
    reducers: {
        setFirstFormValues: (state, action) => {
            state.user.firstName = action.payload.firstName;
            state.user.lastName = action.payload.lastName;
            state.user.birthDate = action.payload.birthDate;
            state.user.professionId = action.payload.professionId;
        },
        setSecondFormValues: (state, action) => {
            state.user.phoneNumber = action.payload.phoneNumber;
            state.user.email = action.payload.email;
            state.user.addressOfResidence = {
                street: action.payload.addressOfResidence?.street ?? "",
                houseNumber: action.payload.addressOfResidence?.houseNumber ?? "",
                flatNumber: action.payload.addressOfResidence?.flatNumber ?? "",
                postalCode: action.payload.addressOfResidence?.postalCode ?? "",
                city: action.payload.addressOfResidence?.city ?? ""
            };

            if (action.payload.isAddressDifferent) {
                state.user.mailingAddress = {
                    street: action.payload.mailingAddress?.street ?? "",
                    houseNumber: action.payload.mailingAddress?.houseNumber ?? "",
                    flatNumber: action.payload.mailingAddress?.flatNumber ?? "",
                    postalCode: action.payload.mailingAddress?.postalCode ?? "",
                    city: action.payload.mailingAddress?.city ?? ""
                };
            } else {
                state.user.mailingAddress = state.user.addressOfResidence;
            }
        },
        setThirdFormValues: (state, action) => {
            state.user.gwExpertsCooperationBeginDate = action.payload.gwExpertsCooperationBeginDate;
            state.user.profileImageId = action.payload.profileImageId;
        },

        setUser: (state, action) => {
            state.user.id = action.payload.id;
            state.user.firstName = action.payload.firstName;
            state.user.lastName = action.payload.lastName;
            state.user.birthDate = action.payload.birthDate;
            state.user.professionId = action.payload.profession?.id;

            state.user.phoneNumber = action.payload.phoneNumber;
            state.user.email = action.payload.email;
            state.user.addressOfResidence = {
                street: action.payload.addressOfResidence?.street ?? "",
                houseNumber: action.payload.addressOfResidence?.houseNumber ?? "",
                flatNumber: action.payload.addressOfResidence?.flatNumber ?? "",
                postalCode: action.payload.addressOfResidence?.postalCode ?? "",
                city: action.payload.addressOfResidence?.city ?? ""
            };
            state.user.mailingAddress = {
                street: action.payload.mailingAddress?.street ?? "",
                houseNumber: action.payload.mailingAddress?.houseNumber ?? "",
                flatNumber: action.payload.mailingAddress?.flatNumber ?? "",
                postalCode: action.payload.mailingAddress?.postalCode ?? "",
                city: action.payload.mailingAddress?.city ?? ""
            };

            state.user.gwExpertsCooperationBeginDate = action.payload.gwExpertsCooperationBeginDate;
            state.user.profileImageId = action.payload.profileImageId;
        },
        resetUser: (state) => {
            state.user = {
                id: 0,
                firstName: "",
                lastName: "",
                birthDate: null,
                professionId: null,

                phoneNumber: "",
                email: "",
                addressOfResidence: {
                    street: "",
                    houseNumber: "",
                    flatNumber: "",
                    postalCode: "",
                    city: ""
                },
                mailingAddress: {
                    street: "",
                    houseNumber: "",
                    flatNumber: "",
                    postalCode: "",
                    city: ""
                },

                gwExpertsCooperationBeginDate: moment().toISOString(),
                profileImageId: null
            };
        }
    }
});

// these methods only modify the state
export const { setFirstFormValues, setSecondFormValues, setThirdFormValues, setUser, resetUser } = userWizardSlice.actions;

// these methods only read the state
export const getWizardUser = (state: any) => state.userWizardSlice.user as ApplicationUserRegisterDto;

export default userWizardSlice.reducer;