import React, {ComponentType, LazyExoticComponent} from "react";
import {ApplicationUserRole} from "../../api";
import {loadingSpin} from "./routingContainer";
import {AuthInitialized, Protected} from "./routingWrappers";
import ManagerRankingListContainer from "../ranking/managers/managerRankingListContainer";
import RankingTiles from "../mainPage/tilesContainer/rankingTiles";

type LazyWithPreload = LazyExoticComponent<ComponentType<any>> & { preload: () => any };

const lazyWithPreload = (factory: () => Promise<any>): LazyWithPreload => {
    const component = React.lazy(factory) as LazyWithPreload;

    component.preload = factory;

    return component;
}

const Login = React.lazy(() => import("../authentication/login/loginPage"));
const Register = React.lazy(() => import("../authentication/register/register"));
const ForgotPassword = React.lazy(() => import("../authentication/forgotPassword/forgotPasswordPage"));
const ForgotPasswordComplete = React.lazy(() => import("../authentication/forgotPasswordComplete/forgotPasswordCompletePage"));
const Activate = React.lazy(() => import("../authentication/activate/activatePage"));
export const ProfessionListPreloadable = lazyWithPreload(() => import("../profession/professionListContainer"));
export const ManagerTitleListPreloadable = lazyWithPreload(() => import("../managerTitle/managerTitleListContainer"));
export const NewsListPreloadable = lazyWithPreload(() => import("../news/newsListContainer"));
export const UserNewsListPreloadable = lazyWithPreload(() => import("../news/userNewsList/userNewsListContainer"));
export const UserNewsDetailsPreloadable = lazyWithPreload(() => import("../news/userNewsList/userNewsDetails"));
const NewsCreateFormContainer = React.lazy(() => import("../news/create/newsCreateFormContainer"));
export const UsersPreloadable = lazyWithPreload(() => import("../user/usersPage"));
export const UserProfilePreloadable = lazyWithPreload(() => import("../user/userProfile/userProfile"));
const CreateUser = React.lazy(() => import("../user/create/createUserPage"));
export const ManagerClientsPreloadable = lazyWithPreload(() => import("../user/managerClientsPage"));
export const ClientProfilePreloadable = lazyWithPreload(() => import("../user/clientProfile/clientProfile"));
const CreateClient = React.lazy(() => import("../user/create/createClientPage"));
const ConfirmDetails = React.lazy(() => import("../authentication/confirmDetails/confirmDetails"));
const Main = React.lazy(() => import("../mainPage/mainPage"));
const ChangePassword = React.lazy(() => import("../user/changePassword/changePassword"));
export const YourProfilePreloadable = lazyWithPreload(() => import("../user/yourProfile/yourProfile"));
export const YourCasesListContainerPreloadable = lazyWithPreload(() => import("../case/caseList/yourCasesListContainer"));
export const ManagerClientsCasesListContainerPreloadable = lazyWithPreload(() => import("../case/caseList/managerClientsCasesListContainer"))
const CaseAddForm = React.lazy(() => import("../case/caseForm/caseFormAddContainer"));
export const YourClientsCaseAddFormPreloadable = lazyWithPreload(() => import("../case/caseForm/yourClientsCaseFormAddContainer"));
export const CaseDetailsPreloadable = lazyWithPreload(() => import("../case/caseDetails/caseDetailsContainer"));
export const UserCasesListContainerPreloadable = lazyWithPreload(() => import("../case/caseList/userCasesListContainer"));
export const YourCaseAddFormPreloadable = lazyWithPreload(() => import("../case/caseForm/yourCaseFormAddContainer"));
const ExpertEmployees = React.lazy(() => import("../user/ExpertEmployeesPage"));
const CreateExpertEmployee = React.lazy(() => import("../user/create/CreateExpertEmployeePage"));
const ExpertEmployeeProfile = React.lazy(() => import("../user/ExpertEmployeeProfile/ExpertEmployeeProfile"));
const ExpertEmployeesCases = React.lazy(() => import("../case/caseList/expertEmployeesCases"));
export const ClientExpertEmployeesPreloadable = lazyWithPreload(() => import("../user/userExpertEmployees/clientExpertEmployees/clientExpertEmployees"));
const ClientExpertEmployeeDetails = React.lazy(() => import("../user/userExpertEmployees/clientExpertEmployees/clientExpertEmployeesDetails"));
const ClientManagerDetails = React.lazy(() => import("../user/userExpertEmployees/clientManager/clientManagerDetails"));
export const AllCasesListContainerPreloadable = lazyWithPreload(() => import("../case/caseList/allCasesListContainer"));
const People = React.lazy(() => import("../user/people"));
const ManagerAttachments = React.lazy(() => import("../staticDocuments/managerAttachments"));
const Calendar = React.lazy(() => import("../calendar/calendarPage"));

export const LoginPage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <Login/>
</AuthInitialized>;

export const RegisterPage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <Register/>
</AuthInitialized>;

export const ForgotPasswordPage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <ForgotPassword/>
</AuthInitialized>;

export const ForgotPasswordCompletePage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <ForgotPasswordComplete/>
</AuthInitialized>;

export const ActivatePage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <Activate/>
</AuthInitialized>;

export const ProfessionListPage = () => <Protected loadingIndicator={loadingSpin}
                                                   allowedForRoles={[ApplicationUserRole.Admin]}>
    <ProfessionListPreloadable/>
</Protected>;

export const ManagerTitleListPage = () => <Protected loadingIndicator={loadingSpin}
                                                     allowedForRoles={[ApplicationUserRole.Admin]}>
    <ManagerTitleListPreloadable/>
</Protected>;

export const NewsListPage = () => <Protected loadingIndicator={loadingSpin}
                                             allowedForRoles={[ApplicationUserRole.Admin]}>
    <NewsListPreloadable/>
</Protected>;

export const CreateNewsPage = () => <Protected loadingIndicator={loadingSpin}
                                               allowedForRoles={[ApplicationUserRole.Admin]}>
    <NewsCreateFormContainer/>
</Protected>

export const UsersPage = () => <Protected loadingIndicator={loadingSpin}
                                          allowedForRoles={[ApplicationUserRole.Admin]}>
    <UsersPreloadable/>
</Protected>;

export const UsersProfilePage = () => <Protected loadingIndicator={loadingSpin}>
    <UserProfilePreloadable/>
</Protected>

export const CreateUserPage = () => <Protected loadingIndicator={loadingSpin}
                                               allowedForRoles={[ApplicationUserRole.Admin]}>
    <CreateUser/>
</Protected>;

export const ExpertEmployeesPage = () => <Protected loadingIndicator={loadingSpin}
                                                    allowedForRoles={[ApplicationUserRole.ExpertOwner]}>
    <ExpertEmployees/>
</Protected>

export const ExpertEmployeesCreatePage = () => <Protected loadingIndicator={loadingSpin}
                                                          allowedForRoles={[ApplicationUserRole.ExpertOwner]}>
    <CreateExpertEmployee/>
</Protected>

export const ExpertEmployeesProfilePage = () => <Protected loadingIndicator={loadingSpin}>
    <ExpertEmployeeProfile/>
</Protected>

export const ManagerClientsPage = () => <Protected loadingIndicator={loadingSpin}
                                                   allowedForRoles={[ApplicationUserRole.Manager]}>
    <ManagerClientsPreloadable/>
</Protected>;

export const YourCasesPage = () => <Protected loadingIndicator={loadingSpin}
                                              allowedForRoles={[ApplicationUserRole.Client]}>
    <YourCasesListContainerPreloadable filters={{hideClient: true, hideCaseStatus: true}}/>
</Protected>;

export const YourCasesExpertPage = () => <Protected loadingIndicator={loadingSpin}
                                                    allowedForRoles={[ApplicationUserRole.ExpertEmployee]}>
    <YourCasesListContainerPreloadable isExpert={true} filters={{hideCaseStatus: true, hideExpert: true}}/>
</Protected>;

export const ExpertEmployeesCasesPage = () => <Protected loadingIndicator={loadingSpin}
                                                         allowedForRoles={[ApplicationUserRole.ExpertOwner]}>
    <ExpertEmployeesCases/>
</Protected>

export const ExpertEmployeesCasesDetails = () => <Protected loadingIndicator={loadingSpin}
                                                            allowedForRoles={[ApplicationUserRole.ExpertOwner]}>
    <CaseDetailsPreloadable isClient={false} isExpert={true}/>
</Protected>;

export const ExpertCaseDetails = () => <Protected loadingIndicator={loadingSpin}
                                                  allowedForRoles={[ApplicationUserRole.ExpertEmployee]}>
    <CaseDetailsPreloadable isClient={false} isExpert={true}/>
</Protected>;

export const ClientExpertEmployeesPage = () => <Protected loadingIndicator={loadingSpin}
                                                          allowedForRoles={[ApplicationUserRole.Client]}>
    <ClientExpertEmployeesPreloadable/>
</Protected>

export const ClientExpertEmployeesDetailsPage = () => <Protected loadingIndicator={loadingSpin}
                                                                 allowedForRoles={[ApplicationUserRole.Client]}>
    <ClientExpertEmployeeDetails/>
</Protected>

export const ClientManagerDetailsPage = () => <Protected loadingIndicator={loadingSpin}
                                                         allowedForRoles={[ApplicationUserRole.Client]}>
    <ClientManagerDetails/>
</Protected>

export const AllCasesPage = () => <Protected loadingIndicator={loadingSpin}
                                             allowedForRoles={[ApplicationUserRole.Admin]}>
    <AllCasesListContainerPreloadable/>
</Protected>;

export const ManagerClientsCasesPage = () => <Protected loadingIndicator={loadingSpin}
                                                        allowedForRoles={[ApplicationUserRole.Manager]}>
    <ManagerClientsCasesListContainerPreloadable/>
</Protected>;

export const ClientProfilePage = () => <Protected loadingIndicator={loadingSpin}>
    <ClientProfilePreloadable/>
</Protected>

export const CreateClientPage = () => <Protected loadingIndicator={loadingSpin}
                                                 allowedForRoles={[ApplicationUserRole.Manager]}>
    <CreateClient/>
</Protected>;

export const MainPage = () => <Protected loadingIndicator={loadingSpin}>
    <Main/>
</Protected>;

export const ConfirmDetailsPage = () => <Protected loadingIndicator={loadingSpin}>
    <ConfirmDetails/>
</Protected>;

export const EditProfilePage = () => <Protected loadingIndicator={loadingSpin}>
    <ConfirmDetails profileEditMode={true}/>
</Protected>;

export const ChangePasswordPage = () => <Protected loadingIndicator={loadingSpin}>
    <ChangePassword/>
</Protected>;

export const YourProfilePage = () => <Protected loadingIndicator={loadingSpin}>
    <YourProfilePreloadable/>
</Protected>;

export const UserNewsListPage = () => <Protected loadingIndicator={loadingSpin}>
    <UserNewsListPreloadable/>
</Protected>;

export const UserNewsDetailsPage = () => <Protected loadingIndicator={loadingSpin}>
    <UserNewsDetailsPreloadable/>
</Protected>;

export const CaseAddPage = () => <Protected loadingIndicator={loadingSpin}
                                            allowedForRoles={[ApplicationUserRole.Admin]}>
    <CaseAddForm/>
</Protected>;

export const YourClientsCaseAddPage = () => <Protected loadingIndicator={loadingSpin}
                                                       allowedForRoles={[ApplicationUserRole.Manager]}>
    <YourClientsCaseAddFormPreloadable/>
</Protected>;

export const CaseDetailsClientPage = () => <Protected loadingIndicator={loadingSpin}>
    <CaseDetailsPreloadable isClient={true}/>
</Protected>;

export const CaseDetailsPage = () => <Protected loadingIndicator={loadingSpin}>
    <CaseDetailsPreloadable isClient={false}/>
</Protected>;

export const ClientCasesPage = () => <Protected loadingIndicator={loadingSpin}
                                                allowedForRoles={[ApplicationUserRole.Manager]}>
    <UserCasesListContainerPreloadable isClient={true}/>
</Protected>

export const UserCasesPage = () => <Protected loadingIndicator={loadingSpin}
                                              allowedForRoles={[ApplicationUserRole.Admin]}>
    <UserCasesListContainerPreloadable/>
</Protected>

export const YourCaseAddPage = () => <Protected loadingIndicator={loadingSpin}
                                                allowedForRoles={[ApplicationUserRole.Client]}>
    <YourCaseAddFormPreloadable/>
</Protected>;

export const PeoplePage = () => <Protected
    loadingIndicator={loadingSpin}
    allowedForRoles={[ApplicationUserRole.Client]}>
    <People/>
</Protected>;

const ProductTableContainer = React.lazy(() => import("../product/productListContainer"));

export const ProductPage = () => <Protected loadingIndicator={loadingSpin}>
    <ProductTableContainer/>
</Protected>
const ProductFormContainer = React.lazy(() => import("../product/productForm/productFormContainer"));

export const ProductFormPage = () => <Protected loadingIndicator={loadingSpin}>
    <ProductFormContainer/>
</Protected>

const SettlementTableContainer = React.lazy(() => import("../settlement/settlementListContainer"));

export const SettlementPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin]}>
    <SettlementTableContainer />
</Protected>

const MySettlementsTableContainer = React.lazy(() => import("../settlement/mySettlementsListContainer"));

export const MySettlementsPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Manager, ApplicationUserRole.Admin]}>
    <MySettlementsTableContainer />
</Protected>

const ClientSettlementsTableContainer = React.lazy(() => import("../settlement/clientSettlementsListContainer"));

export const ClientSettlementsPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Client]}>
    <ClientSettlementsTableContainer />
</Protected>

const IncomeTableContainer = React.lazy(() => import("../settlement/incomeListContainer"));

export const IncomeSettlementsPage = () => <Protected loadingIndicator={loadingSpin}>
    <IncomeTableContainer />
</Protected>

export const ManagerAttachmentsPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <ManagerAttachments />
</Protected>

const AccountingTypeTableContainer = React.lazy(() => import("../accountingType/accountingTypeListContainer"));

export const AccountingTypePage = () => <Protected allowedForRoles={[ApplicationUserRole.Admin]} loadingIndicator={loadingSpin}>
    <AccountingTypeTableContainer />
</Protected>
const AccountingTypeFormContainer = React.lazy(() => import("../accountingType/accountingTypeForm/accountingTypeFormContainer"));

export const AccountingTypeFormPage = () => <Protected allowedForRoles={[ApplicationUserRole.Admin]} loadingIndicator={loadingSpin}>
    <AccountingTypeFormContainer />
</Protected>

export const CalendarPage = () => <Protected loadingIndicator={loadingSpin}>
    <Calendar />
</Protected>
const CalendarEventTableContainer = React.lazy(() => import("../calendarEvent/calendarEventListContainer"));

export const CalendarEventPage = () => <Protected loadingIndicator={loadingSpin}>
    <CalendarEventTableContainer />
</Protected>
const CalendarEventFormContainer = React.lazy(() => import("../calendarEvent/calendarEventForm/calendarEventFormContainer"));

export const CalendarEventFormPage = () => <Protected loadingIndicator={loadingSpin}>
    <CalendarEventFormContainer />
</Protected>
const OrganisationTableContainer = React.lazy(() => import("../organisation/organisationListContainer"));

export const OrganisationPage = () => <Protected allowedForRoles={[ApplicationUserRole.Admin]} loadingIndicator={loadingSpin}>
    <OrganisationTableContainer />
</Protected>
const OrganisationUserTableContainer = React.lazy(() => import("../organisationUser/organisationUserListContainer"));

export const OrganisationUserPage = () => <Protected allowedForRoles={[ApplicationUserRole.Admin]} loadingIndicator={loadingSpin}>
    <OrganisationUserTableContainer />
</Protected>
const SubscriptionDefinitionTableContainer = React.lazy(() => import("../subscriptionDefinition/subscriptionDefinitionListContainer"));

export const SubscriptionDefinitionPage = () => <Protected loadingIndicator={loadingSpin}>
    <SubscriptionDefinitionTableContainer />
</Protected>

const ManagerRanking = React.lazy(() => import("../ranking/managers/managerRankingListContainer"));
export const ManagerRankingPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Manager, ApplicationUserRole.Admin]}>
    <ManagerRanking />
</Protected>

const RankingTilesLazy = React.lazy(() => import("../mainPage/tilesContainer/rankingTiles"));
export const RankingsPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Manager, ApplicationUserRole.Admin]}>
    <RankingTilesLazy />
</Protected>

const DepartmentRanking = React.lazy(() => import("../ranking/departments/departmentRankingListContainer"));
export const DepartmentRankingPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Manager, ApplicationUserRole.Admin]}>
    <DepartmentRanking />
</Protected>

const ClientRanking = React.lazy(() => import("../ranking/clients/clientRankingListContainer"));
export const ClientRankingPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Manager, ApplicationUserRole.Admin]}>
    <ClientRanking />
</Protected>

const ExpertRanking = React.lazy(() => import("../ranking/experts/expertRankingListContainer"));
export const ExpertRankingPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Manager, ApplicationUserRole.Admin]}>
    <ExpertRanking />
</Protected>

const AmbassadorRanking = React.lazy(() => import("../ranking/ambassadors/ambassadorRankingListContainer"));
export const AmbassadorRankingPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Manager, ApplicationUserRole.Admin]}>
    <AmbassadorRanking />
</Protected>

const OverdueSettlements = React.lazy(() => import("../settlement/overdueSettlementListContainer"));
export const OverdueSettlementsPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Manager, ApplicationUserRole.Admin]}>
    <OverdueSettlements />
</Protected>
const MarketingCampaignTableContainer = React.lazy(() => import("../marketingCampaign/marketingCampaignListContainer"));

export const MarketingCampaignPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin]}>
    <MarketingCampaignTableContainer />
</Protected>

const LeadSourceTableContainer = React.lazy(() => import("../leadSource/leadSourceListContainer"));

export const LeadSourcePage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin]}>
    <LeadSourceTableContainer />
</Protected>

const KpiEngineTableContainer = React.lazy(() => import("../kpi/kpiEngineListContainer"));

export const KpiEnginePage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiEngineTableContainer />
</Protected>

const KpiSubscriptionsTableContainer = React.lazy(() => import("../kpi/kpiSubscriptionsListContainer"));

export const KpiSubscriptionsPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiSubscriptionsTableContainer />
</Protected>

const KpiSalesmanTableContainer = React.lazy(() => import("../kpi/kpiSalesmanListContainer"));

export const KpiSalesmanPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiSalesmanTableContainer />
</Protected>

const KpiInvestPitchTableContainer = React.lazy(() => import("../kpi/kpiInvestPitchListContainer"));

export const KpiInvestPitchTable = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiInvestPitchTableContainer />
</Protected>

const KpiInvestInProgressTableContainer = React.lazy(() => import("../kpi/kpiInvestInProgressListContainer"));

export const KpiInvestInProgressTable = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiInvestInProgressTableContainer />
</Protected>

const KpiTaxInProgressTableContainer = React.lazy(() => import("../kpi/kpiTaxInProgressListContainer"));

export const KpiTaxInProgressTable = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiTaxInProgressTableContainer />
</Protected>

const KpiTaxDoneTableContainer = React.lazy(() => import("../kpi/kpiTaxDoneListContainer"));

export const KpiTaxDoneTable = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiTaxDoneTableContainer />
</Protected>

const KpiTaxNewTableContainer = React.lazy(() => import("../kpi/kpiTaxNewListContainer"));

export const KpiTaxNewTable = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiTaxNewTableContainer />
</Protected>

const KpiOverdueTableContainer = React.lazy(() => import("../kpi/kpiOverdueListContainer"));

export const KpiOverdueTable = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiOverdueTableContainer />
</Protected>

const KpiNewBusinessMeetingTableContainer = React.lazy(() => import("../kpi/kpiNewBusinessMeetingListContainer"));

export const KpiNewBusinessMeetingTable = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiNewBusinessMeetingTableContainer />
</Protected>

const KpiServiceMeetingTableContainer = React.lazy(() => import("../kpi/kpiServiceMeetingContainer"));

export const KpiServiceMeetingTable = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiServiceMeetingTableContainer />
</Protected>

const KpiPurgatoryTableContainer = React.lazy(() => import("../kpi/kpiPurgatoryListContainer"));

export const KpiPurgatoryTable = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiPurgatoryTableContainer />
</Protected>

const KpiManagersTableContainer = React.lazy(() => import("../kpi/kpiManagersListContainer"));

export const KpiManagersTable = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiManagersTableContainer />
</Protected>

const KpiNewBusinessTableContainer = React.lazy(() => import("../kpi/kpiNewBusinessListContainer"));

export const KpiNewBusinessTable = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiNewBusinessTableContainer />
</Protected>

const KpiRecruitmentTableContainer = React.lazy(() => import("../kpi/kpiRecruitmentListContainer"));

export const KpiRecruitmentTable = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiRecruitmentTableContainer />
</Protected>

const KpiChfTableContainer = React.lazy(() => import("../kpi/kpiChfListContainer"));

export const KpiChfTable = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiChfTableContainer />
</Protected>

const KpiHellTableContainer = React.lazy(() => import("../kpi/kpiHellListContainer"));

export const KpiHellTable = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiHellTableContainer />
</Protected>

const KpiComplianceTableContainer = React.lazy(() => import("../kpi/kpiComplianceListContainer"));

export const KpiComplianceTable = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiComplianceTableContainer />
</Protected>

const KpiClientActivityTableContainer = React.lazy(() => import("../kpi/kpiClientActivityListContainer"));

export const KpiClientActivityTable = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiClientActivityTableContainer />
</Protected>

const KpiTaxGroupContainer = React.lazy(() => import("../kpi/group/kpiTaxGroupContainer"));

export const KpiTaxGroup = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiTaxGroupContainer />
</Protected>

const KpiLawGroupContainer = React.lazy(() => import("../kpi/group/kpiLawGroupContainer"));

export const KpiLawGroup = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiLawGroupContainer />
</Protected>

const KpiExpertSettlementsGroupContainer = React.lazy(() => import("../kpi/group/kpiExpertSettlementsContainer"));

export const KpiExpertSettlementsGroup = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiExpertSettlementsGroupContainer />
</Protected>

const KpiRecruitmentGroupContainer = React.lazy(() => import("../kpi/group/kpiRecruitmentGroupContainer"));

export const KpiRecruitmentGroup = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiRecruitmentGroupContainer />
</Protected>

const KpiInvestSalesTableContainer = React.lazy(() => import("../kpi/kpiInvestSalesListContainer"));

export const KpiInvestSalesTable = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiInvestSalesTableContainer />
</Protected>

const KpiLegalTableContainer = React.lazy(() => import("../kpi/kpiLegalListContainer"));

export const KpiLegalTable = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiLegalTableContainer />
</Protected>

const KpiLeadsTableContainer = React.lazy(() => import("../kpi/kpiLeadsListContainer"));

export const KpiLeadsTable = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiLeadsTableContainer />
</Protected>

const KpiLawSettlementsTableContainer = React.lazy(() => import("../kpi/settlements/kpiLawSettlementsContainer"));

export const KpiLawSettlementsTable = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiLawSettlementsTableContainer />
</Protected>

const KpiTaxSettlementsTableContainer = React.lazy(() => import("../kpi/settlements/kpiTaxSettlementsContainer"));

export const KpiTaxSettlementsTable = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={[ApplicationUserRole.Admin, ApplicationUserRole.Manager]}>
    <KpiTaxSettlementsTableContainer />
</Protected>

const DepartmentTableContainer = React.lazy(() => import("../department/departmentListContainer"));

export const DepartmentPage = () => <Protected loadingIndicator={loadingSpin}>
    <DepartmentTableContainer />
</Protected>

const AccountingAttachmentTypeTableContainer = React.lazy(() => import("../accountingAttachmentType/accountingAttachmentTypeListContainer"));

export const AccountingAttachmentTypePage = () => <Protected loadingIndicator={loadingSpin}>
    <AccountingAttachmentTypeTableContainer />
</Protected>
const AccountingAttachmentTypeFormContainer = React.lazy(() => import("../accountingAttachmentType/accountingAttachmentTypeForm/accountingAttachmentTypeFormContainer"));

export const AccountingAttachmentTypeFormPage = () => <Protected loadingIndicator={loadingSpin}>
    <AccountingAttachmentTypeFormContainer />
</Protected>

const InvoiceFormContainer = React.lazy(() => import("../invoice/invoiceFormContainer"));

export const InvoiceForm = () => <Protected loadingIndicator={loadingSpin}>
    <InvoiceFormContainer />
</Protected>