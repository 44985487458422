import {Header} from "antd/lib/layout/layout";
import {User} from "oidc-react";
import React, { useEffect, useState } from 'react';
import styles from './headerComponent.module.less';
import Logo from './logo';
import UserInfoContainer from "../userInfo/userInfoContainer";
import classNames from "classnames";

interface HeaderComponentProps {
    onLogoutPressed: () => Promise<any>,
    onLoginPressed: () => Promise<any>,
    userData?: User | null;
}

export default function HeaderComponent(props: HeaderComponentProps) {

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            setScrolled(window.scrollY > 20);
        }

        document.addEventListener("scroll", onScroll);

        return () => document.removeEventListener("scroll", onScroll);
    }, []);

    const className = scrolled ? classNames(styles.exHeader, styles.exHeaderScrolled) : styles.exHeader;

    if (props.userData) {
        return <Header className={className}>
            <Logo/>
            <UserInfoContainer onLogoutPressed={props.onLogoutPressed}/>
        </Header>;
    }

    return <Header className={className}>
        <Logo/>
    </Header>;
}
