import UserInfo from "./userInfo";
import {Menu} from "antd";
import {useNavigate} from "react-router-dom";
import {AsyncButton} from "digimuth-components/dist";
import React from "react";
import {RoutingPaths} from "../../pages/routing/routingContainer";

export default function UserInfoContainer(props: userInfoContainerComponentProps) {
    const navigate = useNavigate();

    const menu = (
        <Menu
            items={[
                {
                    label: <AsyncButton onClick={() => navigate(RoutingPaths.yourProfile.route)} type="text">Twój
                        profil</AsyncButton>,
                    key: '0',
                },
                {
                    label: <AsyncButton onClick={props.onLogoutPressed}>Wyloguj</AsyncButton>,
                    key: '1',
                }
            ]}
        />
    );

    return <UserInfo onLogoutPressed={props.onLogoutPressed} menu={menu} />
}

interface userInfoContainerComponentProps {
    onLogoutPressed: () => Promise<any>
}
