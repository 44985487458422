import { createSlice } from "@reduxjs/toolkit";

 export interface AlertObj {
     message: string;
     description: string;
     type: 'success' | 'warning' | 'error' | 'info';
 }

 const initialState: { alert: AlertObj | null, isChangingOrganisation: boolean } = {
    alert: null,
    isChangingOrganisation: false
 };

 const alertSlice = createSlice({
     name: 'alertSlice',
     initialState,
     reducers: {
         setAlert: (state, action) => {
             state.alert = action.payload;
         },
         setIsChangingOrganisation: (state, action) => {
            state.isChangingOrganisation = action.payload;
         }
     }
 });

 // these methods only modify the state
 export const { setAlert, setIsChangingOrganisation } = alertSlice.actions;

 // these methods only read the state
 export const getAlert = (state: any) => state.alert.alert as AlertObj;
 export const getIsChangingOrganisation = (state: any) => state.alert.isChangingOrganisation as boolean;

 export default alertSlice.reducer;