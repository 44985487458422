import { createSlice } from "@reduxjs/toolkit";
import { DepartmentDto, ProductDto } from "../api";

 const initialState: { products: ProductDto[] | null, departments: DepartmentDto[] | null } = {
     products: null,
     departments: null
 };

 const productSlice = createSlice({
     name: 'productSlice',
     initialState,
     reducers: {
         setProducts: (state, action) => {
             state.products = action.payload;
         },
         setDepartments: (state, action) => {
             state.departments = action.payload;
         }
     }
 });

 // these methods only modify the state
 export const { setProducts, setDepartments } = productSlice.actions;

 // these methods only read the state
 export const getProducts = (state: any) => state.product.products as ProductDto[] | null;
 export const getDepartments = (state: any) => state.product.departments as DepartmentDto[] | null;

 export default productSlice.reducer;