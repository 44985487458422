import { useDispatch } from "react-redux";
import { setIsChangingOrganisation } from "../redux/alertSlice";

let organisationId: number | null = null;

export function getOrganisationId() {
    if (organisationId === null) {
        const search = new URLSearchParams(window.location.search);
        const organisationFromSearch = search.get("organisationId");
        const organisationFromSession = sessionStorage.getItem("organisationId");
        const organisationFromLocal = localStorage.getItem("organisationId");

        console.log(organisationFromSearch, "organisationFromSearch");

        organisationId = +(organisationFromSearch || organisationFromSession || organisationFromLocal || 0);

        if (organisationFromSearch) {
            search.delete("organisationId");
        }

        if (organisationFromSession === null || organisationFromSearch) {
            sessionStorage.setItem("organisationId", organisationId.toString());
        }
        if (organisationFromLocal === null || organisationFromSearch) {
            localStorage.setItem("organisationId", organisationId.toString());
        }

        if (organisationFromSearch && organisationFromSearch !== organisationFromSession) {
            window.location.search = search.toString();
        }
    }

    return organisationId;
}

export default function useCurrentOrganisation() {

    const dispatch = useDispatch();

    getOrganisationId();

    const setOrganisationId = (id: number) => {
        dispatch(setIsChangingOrganisation(true));
        sessionStorage.setItem("organisationId", id.toString());
        localStorage.setItem("organisationId", id.toString());
        window.location.href = "/";
    }

    return { setOrganisationId, organisationId: organisationId || 0 };
}