import { configureStore } from "@reduxjs/toolkit";
import alertReducer from './alertSlice';
import applicationUserSlice from "./applicationUserSlice";
import professionSlice from "./professionSlice";
import userWizardSlice from "./userWizardSlice";
import notificationSlice from "./notificationSlice";
import productSlice from "./productSlice";
import organisationSlice from "./organisationSlice";

export const store = configureStore({
    reducer: {
        alert: alertReducer,
        applicationUser: applicationUserSlice,
        userWizardSlice: userWizardSlice,
        profession: professionSlice,
        notificationSlice: notificationSlice,
        product: productSlice,
        organisation: organisationSlice
    }
});
