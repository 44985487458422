import styles from "./userinfo.module.less";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentUser, setCurrentUser} from "../../redux/applicationUserSlice";
import {Dropdown, Space} from "antd";
import {useInjection} from "inversify-react";
import ApiService from "../../services/apiService";
import {useAuth} from "oidc-react";
import {ApplicationUserApi, NewsApi} from "../../api";
import SkeletonButton from "antd/lib/skeleton/Button";
import SkeletonAvatar from "antd/lib/skeleton/Avatar";
import ProfilePicture from "../profilePicture/profilePicture";
import Notifications from "./notifications";
import MobileMenu from "../mobileMenu/mobileMenu";

export default function UserInfo(props: userInfoComponentProps) {
    const currentUser = useSelector(getCurrentUser);
    const apiService = useInjection(ApiService);
    const auth = useAuth();
    const dispatch = useDispatch();
    const [notificationsVisible, setNotificationsVisible] = useState<boolean>(false);

    const updateCurrentUserInStore = useCallback(async () => {
        const response = await apiService.getApi(ApplicationUserApi).apiApplicationUserIdGet(Number(auth.userData?.profile.sub));
        dispatch(setCurrentUser(response.data));
    }, [auth.userData, apiService, dispatch]);

    useEffect(() => {
        updateCurrentUserInStore();
    }, [updateCurrentUserInStore]);

    return <div className={styles.userInfo}>
        <Notifications notificationsVisible={notificationsVisible} setNotificationsVisible={setNotificationsVisible} />
        <div className={styles.desktopOnly}>
        {
            currentUser ? <>
                    <Dropdown overlay={props.menu} trigger={['click']}>
                        <div className={styles.userName}>
                            {`${currentUser.firstName} ${currentUser.lastName}`}
                        </div>
                    </Dropdown>
                    <Dropdown overlay={props.menu} trigger={['click']}>
                        <div>
                            <ProfilePicture profilePictureId={currentUser.profileImageId} className={styles.avatar}/>
                        </div>
                    </Dropdown>
                </>
                : <Space size={["large", "large"]}>
                    <SkeletonButton active={true} size="large"/>
                    <SkeletonAvatar active={true} size={48}/>
                </Space>
        }
        </div>

        <MobileMenu onNotificationsPressed={() => setNotificationsVisible(true)} onLogoutPressed={props.onLogoutPressed}/>
    </div>
}

interface userInfoComponentProps {
    onLogoutPressed: () => Promise<any>
    menu: any
}
