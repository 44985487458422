import { useAuth } from "oidc-react";
import { useSelector } from "react-redux";
import { ApplicationUserRole } from "../api";
import { getCurrentUser } from "../redux/applicationUserSlice";
import { getOrganisationId } from "./useCurrentOrganisation";

export default function useCurrentUserRoles(): ApplicationUserRole[] {
    const auth = useAuth();
    const currentUser = useSelector(getCurrentUser);
    const organisationId = getOrganisationId();

    if (!auth.userData || !auth.userData.profile.applicationUserRoles) {
        return [];
    }

    if (!currentUser) {
        return [];
    }

    if (!organisationId) {
        return currentUser.roles!;
    }

    let organisationRoles = currentUser.organisationUsers?.find(u => u.organisationId === organisationId)?.roles || [];

    if (currentUser.roles?.includes(ApplicationUserRole.Admin) && !organisationRoles?.includes(ApplicationUserRole.Admin)) {
        organisationRoles = [...organisationRoles, ApplicationUserRole.Admin];
    }

    return organisationRoles;
}