import { Select } from "antd";
import classNames from "classnames";
import { LawsuitStatus } from "../../api";
import styles from "./lawsuitStatusSelector.module.less";

const { Option } = Select;

function getNameForLawsuitStatus(status?: LawsuitStatus) {
    switch (status) {
        case LawsuitStatus.InProgress: return "W toku";
        case LawsuitStatus.Won: return "Wygrana";
        case LawsuitStatus.Lost: return "Przegrana";
        default: return "Brak";
    }
}

export default function LawsuitStatusSelector(props: LawsuitStatusSelectorProps) {
    const { editable, value, hideNoneValue } = props;

    const statusClassName = (() => {
        switch (value) {
            case LawsuitStatus.InProgress: return styles.inProgress;
            case LawsuitStatus.Lost: return styles.lost;
            case LawsuitStatus.Won: return styles.won;
            default: return styles.new;
        }
    })();
    
    const className = editable ? classNames(styles.wrapper, statusClassName) : classNames(styles.wrapper, statusClassName, styles.nonEditable);

    return <Select
            dropdownMatchSelectWidth={false}
            showArrow={editable}
            disabled={!editable}
            value={value || LawsuitStatus.None}
            onChange={props.onChange}
            className={className}>
        <Option value={LawsuitStatus.None} className={styles.option} disabled={hideNoneValue}>
            {getNameForLawsuitStatus(LawsuitStatus.None)}
        </Option>
        <Option value={LawsuitStatus.InProgress} className={styles.option}>
            {getNameForLawsuitStatus(LawsuitStatus.InProgress)}
        </Option>
        <Option value={LawsuitStatus.Won} className={styles.option}>
            {getNameForLawsuitStatus(LawsuitStatus.Won)}
        </Option>
        <Option value={LawsuitStatus.Lost} className={styles.option}>
            {getNameForLawsuitStatus(LawsuitStatus.Lost)}
        </Option>
    </Select>;
}

interface LawsuitStatusSelectorProps {
    value?: LawsuitStatus,
    editable: boolean,
    onChange: (value: LawsuitStatus) => Promise<any>,
    hideNoneValue?: boolean
}
