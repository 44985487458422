import { Select, SelectProps, Skeleton } from "antd";
import classNames from "classnames";
import { useInjection } from "inversify-react";
import { useCallback, useEffect } from "react";
import { CaseApi, CaseType, DepartmentApi, ProductApi, ProductDto } from "../../api";
import { CaseDetailsMainTileProps } from "../../pages/case/caseDetails/caseDetailsMainTile";
import ApiService from "../../services/apiService";
import styles from "./caseTypeSelector.module.less";
import { useDispatch, useSelector } from "react-redux";
import { getDepartments, getProducts, setDepartments, setProducts } from "../../redux/productSlice";

const caseTypes = {
    [CaseType.ForeignCurrencyLoan]: "Kredyt walutowy - pozwanie banku",
    [CaseType.ForeignCurrencyLoanDefense]: "Kredyt walutowy - obrona przed pozwem banku",
    [CaseType.ForeignCurrencyLoanCassation]: "Kredyt walutowy - kasacja",
    [CaseType.ForeignCurrencyLoanMediation]: "Kredyt walutowy - mediacja",
    [CaseType.ForeignCurrencyLoanSettlement]: "Kredyt walutowy - zawezwanie do próby ugodowej",
    [CaseType.LiquidationFee]: "Opłata likwidacyjna",
    [CaseType.Unww]: "UNWW",
    [CaseType.LawsuitCivil]: "Sprawa sądowa - cywilna",
    [CaseType.LawsuitCriminal]: "Sprawa sądowa - karna",
    [CaseType.LegalAssistance]: "Pomoc prawna",
    [CaseType.Divorce]: "Rozwód",
    [CaseType.Accounting]: "Księgowość",
    [CaseType.Recruitment]: "Rekrutacja",
    [CaseType.Leasing]: "Leasing",
    [CaseType.Insurance]: "Ubezpieczenie",
    [CaseType.Loan]: "Kredyt",
    [CaseType.Consultation]: "Konsultacja",
    [CaseType.Marketing]: "Marketing",
    [CaseType.Amazon]: "Amazon",
    [CaseType.GweStock]: "Akcje Goodwill Experts S.A.",
    [CaseType.Investment]: "Inwestycja",
    [CaseType.It]: "IT",
    [CaseType.DebtCollection]: "Windykacja",
    [CaseType.Other]: "Inna",
    [CaseType.PeriodicOther]: "Inna (cykliczna)"
} 

export function getNameForCaseType(type?: CaseType) {
    return caseTypes[type || CaseType.Other] || "Inna";
}

export function getCaseTypeArray(): CaseType[] {
    return Object.keys(caseTypes) as CaseType[];
}

export default function CaseProductSelector(props: CaseDetailsMainTileProps) {
    const apiService = useInjection(ApiService);
    const dispatch = useDispatch();
    const allProducts = useSelector(getProducts);
    const departments = useSelector(getDepartments);
    const { editable, currentCase, updateCase } = props;
    const { id } = currentCase;

    useEffect(() => {
        const updateProducts = async () => {
            const response = await apiService.getApi(ProductApi).apiProductMinimalGet();

        dispatch(setProducts(response.data));
        }

        updateProducts();
    }, [apiService, dispatch]);

    useEffect(() => {
        const updateDepartments = async () => {
            const response = await apiService.getApi(DepartmentApi).apiDepartmentGet();

            dispatch(setDepartments(response.data));
        }

        updateDepartments();
    }, [apiService, dispatch]);

    const changeProduct = useCallback(async (productId: number) => {
        const response = await apiService.getApi(CaseApi).apiCaseIdProductPut(id!, productId);

        return response.data;
    }, [apiService, id]);

    const onChange = useCallback((type: number) => {
        return updateCase(() => changeProduct(type));
    }, [changeProduct, updateCase]);

    if (!allProducts || !departments) {
        return <Skeleton.Input size="small" />
    }

    const options = [...departments, null].map(d => ({
        label: d?.name || "(bez departamentu)",
        options: allProducts.filter(p => p.departmentId == d?.id).map(p => ({ value: p.id, label: p.name })).sort((a, b) => a.label!.localeCompare(b.label!))
    }));

    return <Select
        bordered={false}
        dropdownMatchSelectWidth={false}
        showArrow={editable}
        disabled={!editable}
        value={currentCase.productId}
        onChange={onChange}
        className={!editable ? classNames(styles.nonEditable, styles.wrapper) : styles.wrapper}
        options={options} />;
}

export function CaseTypeFormSelect(props: SelectProps & { onChangeProduct?: (product: ProductDto) => any}) {
    const apiService = useInjection(ApiService);
    const dispatch = useDispatch();
    const allProducts = useSelector(getProducts);
    const departments = useSelector(getDepartments);

    useEffect(() => {
        const updateProducts = async () => {
            const response = await apiService.getApi(ProductApi).apiProductMinimalGet();

            dispatch(setProducts(response.data));
        }

        updateProducts();
    }, [apiService, dispatch]);

    useEffect(() => {
        const updateDepartments = async () => {
            const response = await apiService.getApi(DepartmentApi).apiDepartmentGet();

            dispatch(setDepartments(response.data));
        }

        updateDepartments();
    }, [apiService, dispatch]);

    if (!allProducts || !departments) {
        return <Skeleton.Input size="small" />
    }

    const options = [...departments, null].map(d => ({
        label: d?.name || "(bez departamentu)",
        options: allProducts.filter(p => p.departmentId == d?.id).map(p => ({ value: p.id, label: p.name })).sort((a, b) => a.label!.localeCompare(b.label!, undefined, { numeric: true, sensitivity: "base" }))
    }));

    return <Select
            options={options}
            listHeight={500}
            dropdownMatchSelectWidth={false}
            {...props}
            onChange={(id, option) => {
                props.onChangeProduct?.(allProducts.find(p => p.id === id)!)
                props.onChange?.(id, option);
            }} />
}
