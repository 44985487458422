import { createSlice } from "@reduxjs/toolkit";
import { ApplicationUserDetailsDto, ApplicationUserRole } from "../api";

 const initialState: { currentUser: ApplicationUserDetailsDto | null, lastRoleTab?: ApplicationUserRole, lastKpiTab?: string } = {
    currentUser: null
 };

 const applicationUserSlice = createSlice({
     name: 'applicationUserSlice',
     initialState,
     reducers: {
         setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
         },
         setLastRoleTab: (state, action) => {
            state.lastRoleTab = action.payload
         },
         setLastKpiTab: (state, action) => {
            state.lastKpiTab = action.payload;
         }
     }
 });

 // these methods only modify the state
 export const { setCurrentUser, setLastRoleTab, setLastKpiTab } = applicationUserSlice.actions;

 // these methods only read the state
 export const getCurrentUser = (state: any) => state.applicationUser.currentUser as ApplicationUserDetailsDto | null;
 export const getLastRoleTab = (state: any) => state.applicationUser.lastRoleTab as ApplicationUserRole | undefined;
 export const getLastKpiTab = (state: any) => state.applicationUser.lastKpiTab as string | undefined;

 export default applicationUserSlice.reducer;