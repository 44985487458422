import React, { useEffect, useState } from 'react';
import styles from './headerComponent.module.less';
import { Link } from "react-router-dom";
import { ReactComponent as LogoSvg } from '../../logo.svg';
import { RoutingPaths } from '../../pages/routing/routingContainer';
import { useCurrentRoutingPath } from 'digimuth-components/dist';
import { useInjection } from 'inversify-react';
import ApiService from '../../services/apiService';
import { OrganisationApi, OrganisationDto } from '../../api';
import { Select, Space, Typography } from 'antd';
import { useAuth } from 'oidc-react';
import useCurrentOrganisation from '../../hooks/useCurrentOrganisation';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganisations, setOrganisations } from '../../redux/organisationSlice';

export default function Logo() {
    const currentRoute = useCurrentRoutingPath(RoutingPaths);
    const apiService = useInjection(ApiService);
    const dispatch = useDispatch();
    const organisations = useSelector(getOrganisations);
    const { organisationId, setOrganisationId } = useCurrentOrganisation();
    const auth = useAuth();

    useEffect(() => {
        if (auth.userData) {
            apiService.getApi(OrganisationApi).apiOrganisationContextUserGet().then(r => dispatch(setOrganisations(r.data)));
        }
    }, [auth, dispatch]);

    if (currentRoute === RoutingPaths.login || currentRoute === RoutingPaths.forgotPassword) {
        return <LoginLogo />;
    }

    return <div className={styles.logoWrapper}>
        <Link to={RoutingPaths.mainPage.route}>
            <LogoSvg className={styles.logo} title='Goodwill Experts logo' />
        </Link>
        <Link className={styles.logoTexts} to={RoutingPaths.mainPage.route}>
            <h3 style={process.env.NODE_ENV === "development" ? { color: "red" } : undefined} className={styles.logoName}>{process.env.NODE_ENV === "development" ? "GoodNet TEST" : "Goodwill Experts"}</h3>
            <div className={styles.logoSubtext}>Wykorzystaj moc rozwiązań</div>
        </Link>
        {(organisations?.length || 0) > 1 && <div className={styles.organisationSelectorWrapper}>
            <Typography.Text ellipsis={true} className={styles.organisationSelectorLabel} type="secondary">Organizacja</Typography.Text>
            <Select className={styles.organisationSelector} value={organisationId} onChange={id => setOrganisationId(id)} listHeight={750} dropdownMatchSelectWidth={false} bordered={false} options={organisations.map(o => ({ label: o.name, value: o.id }))} />
        </div>}
    </div>;
}

function LoginLogo() {
    return <div className={styles.logoWrapperLogin}>
        <Link to={RoutingPaths.mainPage.route} className={styles.logoLogin}>
            <LogoSvg title='Goodwill Experts logo' />
        </Link>
        <Link className={styles.logoTextsLogin} to={RoutingPaths.mainPage.route}>
            <h3 className={styles.logoName}>Goodwill Experts</h3>
            <div className={styles.logoSubtext}>Wykorzystaj moc rozwiązań</div>
        </Link>
    </div>;
}
