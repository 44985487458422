import { UserOutlined } from "@ant-design/icons";
import { createCache, useCache } from "@react-hook/cache";
import { Avatar } from "antd"
import SkeletonAvatar from "antd/lib/skeleton/Avatar";
import { useInjection } from "inversify-react";
import { useEffect } from "react";
import { ProfilePictureApi } from "../../api";
import ApiService from "../../services/apiService";
import { useInView } from "react-intersection-observer";

const profilePictureCache = createCache(async (key, apiService) => {

    if (!key) {
        return;
    }

    const response = await apiService.getApi(ProfilePictureApi)
        .apiProfilePictureIdThumbnailGet(key, { responseType: 'blob' });
    
    return URL.createObjectURL(response.data!);
}, 5000);

export default function ProfilePicture(props: ProfilePictureProps) {
    const { ref, inView } = useInView();
    const apiService = useInjection(ApiService);

    const [{value, status}, reloadImage] = useCache(profilePictureCache, props.profilePictureId?.toString() || "", apiService);

    useEffect(() => {
        if (status === "idle" && inView) {
            reloadImage();
        }
    }, [status, inView]);

    return <div style={{ display: "inline-block" }} ref={ref}>{(props.profilePictureId && !value)
        ? <SkeletonAvatar className={props.className} size={props.size || 48} active={true} />
        : <Avatar className={props.className} src={value} size={props.size || 48} style={{ minWidth: props.size || 48 }} icon={<UserOutlined />} />}
    </div>;
}

interface ProfilePictureProps {
    profilePictureId?: number | null,
    size?: number,
    className?: string,
    icon?: any
}
