import { Select } from "antd";
import classNames from "classnames";
import { useInjection } from "inversify-react";
import { useCallback } from "react";
import { CaseApi, CaseDetailsDto, CasePeriodDetailsDto, CasePeriodStatus } from "../../api";
import ApiService from "../../services/apiService";
import styles from "./casePeriodStatusSelector.module.less";

const { Option } = Select;

export function getNameForCasePeriodStatus(status?: CasePeriodStatus) {
    switch (status) {
        case CasePeriodStatus.InProgress: return "W toku";
        case CasePeriodStatus.ReadyForAccounting: return "Gotowy do księgowania";
        case CasePeriodStatus.Done: return "Zakończono";
        default: return "Nie rozpoczęto";
    }
}

export default function CasePeriodStatusSelector(props: CasePeriodStatusSelectorProps) {
    const apiService = useInjection(ApiService);
    const { editable, currentPeriod, updateCase } = props;

    const value = currentPeriod.status || CasePeriodStatus.NotStarted;

    const statusClassName = (() => {
        switch (value) {
            case CasePeriodStatus.NotStarted: return styles.new;
            case CasePeriodStatus.InProgress: return styles.inProgress;
            case CasePeriodStatus.ReadyForAccounting: return styles.ready;
            case CasePeriodStatus.Done: return styles.done;
        }
    })();

    const changeStatus = useCallback(async (status: CasePeriodStatus) => {
        const { caseId, month, year } = currentPeriod;
        const response = await apiService.getApi(CaseApi).apiCaseIdPeriodStatusPut(caseId!, month, year, status);

        return response.data;
    }, [apiService, currentPeriod]);

    const onChange = useCallback((status: CasePeriodStatus) => {
        return updateCase(() => changeStatus(status));
    }, [changeStatus, updateCase]);

    const className = editable ? classNames(styles.wrapper, statusClassName) : classNames(styles.wrapper, statusClassName, styles.nonEditable);

    return <Select
            dropdownMatchSelectWidth={false}
            showArrow={editable}
            disabled={!editable}
            value={value}
            onChange={onChange}
            className={className}>
        <Option value={CasePeriodStatus.NotStarted} className={classNames(styles.option, styles.new)}>{getNameForCasePeriodStatus(CasePeriodStatus.NotStarted)}</Option>
        <Option value={CasePeriodStatus.InProgress} className={classNames(styles.option, styles.inProgress)}>{getNameForCasePeriodStatus(CasePeriodStatus.InProgress)}</Option>
        <Option value={CasePeriodStatus.ReadyForAccounting} className={classNames(styles.option, styles.ready)}>{getNameForCasePeriodStatus(CasePeriodStatus.ReadyForAccounting)}</Option>
        <Option value={CasePeriodStatus.Done} className={classNames(styles.option, styles.done)}>{getNameForCasePeriodStatus(CasePeriodStatus.Done)}</Option>
    </Select>;
}

interface CasePeriodStatusSelectorProps {
    currentPeriod: CasePeriodDetailsDto,
    editable: boolean,
    updateCase: (u: () => Promise<CaseDetailsDto>) => Promise<any>,
}