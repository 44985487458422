import './App.less';
import { Provider as InversifyProvider } from 'inversify-react';
import {Provider, useDispatch, useSelector} from 'react-redux'
import {store} from './redux/store';
import RoutingContainer, { loadingSpin } from "./pages/routing/routingContainer";
import React, { useEffect } from "react";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import ApiService from "./services/apiService";
import { CrudLayout } from 'digimuth-components/dist';
import { Container } from 'inversify';
import { AuthProvider, AuthProviderProps, initUserManager, useAuth, UserManager } from 'oidc-react';
import SiderMenu from './components/siderMenu/siderMenu';
import HeaderContainer from './components/header/headerContainer';
import { ConfigProvider, Spin } from 'antd';
import plPL from 'antd/lib/locale/pl_PL';
import { HubConnectionBuilder } from "@microsoft/signalr";
import { NotificationDto } from './api';
import { setLatestNotification } from './redux/notificationSlice';
import { getIsChangingOrganisation } from './redux/alertSlice';

const authSettings: AuthProviderProps = {
    authority: process.env.REACT_APP_URL,
    clientId: "Digimuth.GwExperts.Web",
    redirectUri: process.env.REACT_APP_URL,
    autoSignIn: false,
    automaticSilentRenew: true,
    scope: "openid Digimuth.GwExperts.WebAPI profile offline_access"
};

export const userManager: UserManager | undefined = initUserManager(authSettings);

function App() {

    const container = new Container();
    const history = createBrowserHistory();
    container.bind(ApiService).toSelf().inSingletonScope();

    return (
            /*
        // @ts-ignore */
        <AuthProvider
            userManager={userManager}
            onSignIn={u => {
                const fromState = u?.state?.targetPath;
                const targetUrl = fromState ? fromState : "/";
                history.replace(targetUrl);
            }}
            autoSignIn={false}>
            <ConfigProvider locale={plPL}>
                <Provider store={store}>
                    <InversifyProvider container={container}>
                        <HistoryRouter history={history}>
                            <Layout />
                        </HistoryRouter>
                    </InversifyProvider>
                </Provider>
            </ConfigProvider>
        </AuthProvider>
    )
}

const sendNotificationMethodName = "sendNotification";

function Layout() {
    const auth = useAuth();
    const dispatch = useDispatch();
    const isChangingOrganisation = useSelector(getIsChangingOrganisation);

    useEffect(() => {
        if (!auth.userData) {
            return;
        }

        const hubConnection = new HubConnectionBuilder()
            .withUrl("/hubs/notifications", { accessTokenFactory: () => auth.userData!.access_token })
            .withAutomaticReconnect()
            .build();

        hubConnection.on(sendNotificationMethodName, (data: NotificationDto) => {
            dispatch(setLatestNotification(data));
        });

        hubConnection.start();

        return () => {
            hubConnection.off(sendNotificationMethodName);
            hubConnection.stop();
        }
    }, [auth, dispatch]);
    
    return <Spin spinning={isChangingOrganisation}>
        <CrudLayout
            breadcrumb={<></>}
            siderMenu={<SiderMenu />}
            header={<HeaderContainer />}
            footerContent={<></>}
            showSider={false}
            >
            <RoutingContainer/>
        </CrudLayout>
    </Spin>
}

export default App;
