import {BellFilled, BellOutlined, CalendarOutlined, ReadOutlined} from "@ant-design/icons";
import {Avatar, Badge, Dropdown, List, notification, Spin, Tabs} from "antd";
import classNames from "classnames";
import {useInjection} from "inversify-react";
import moment from "moment";
import {ApplicationUserRole, CaseHistoryValues, NotificationDto, NotificationsApi, ProductApi} from "../../api";
import {getLatestNotification, getNotifications, setNotificationsData} from "../../redux/notificationSlice";
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {CaseDetailsPreloadable, UserNewsDetailsPreloadable} from "../../pages/routing/pages";
import {RoutingPaths, loadingSpin} from "../../pages/routing/routingContainer";
import ApiService from "../../services/apiService";
import styles from "./notifications.module.less";
import 'moment/locale/pl';
import ProfilePicture from "../profilePicture/profilePicture";
import {
    getTimelineActionDescription,
    getTimelineFieldName
} from "../../pages/case/caseDetails/caseActivity/caseDetailsTimeline";
import {AsyncButton} from "digimuth-components/dist";
import Paragraph from "antd/lib/typography/Paragraph";
import InfiniteScroll from "react-infinite-scroll-component";
import {getProducts, setProducts} from "../../redux/productSlice";
import {getSimpleRoleName} from "../../helpers/roleHelpers";


export default function Notifications(props: { notificationsVisible: boolean, setNotificationsVisible: (v: boolean) => any }) {
    const apiService = useInjection(ApiService);
    const dispatch = useDispatch();
    const notifications = useSelector(getNotifications);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const latestNotification = useSelector(getLatestNotification);
    const [notificationsApi, contextHolder] = notification.useNotification();

    useEffect(() => {
        if (!latestNotification) {
            return;
        }

        notificationsApi.open({
            maxCount: 3,
            duration: 3,
            top: 65,
            key: latestNotification.createdAt,
            message: "Powiadomienie",
            description: <NotificationTile hideBadge={true} notification={latestNotification}/>
        });

        updateNotifications();
    }, [latestNotification]);

    const updateNotifications = useCallback(async () => {
        setIsLoading(true);

        try {
            const newsNotifications = await apiService.getApi(NotificationsApi).apiNotificationsGet();
            dispatch(setNotificationsData(newsNotifications.data));
        } finally {
            setIsLoading(false);
        }
    }, [apiService]);

    const clearNotifications = useCallback(async () => {
        setIsLoading(true);

        try {
            const newsNotifications = await apiService.getApi(NotificationsApi).apiNotificationsClearPost();
            dispatch(setNotificationsData(newsNotifications.data));
        } finally {
            setIsLoading(false);
        }

    }, [apiService]);

    useEffect(() => {
        updateNotifications();
    }, [updateNotifications]);

    const onSetVisible = (v: boolean) => {
        props.setNotificationsVisible(v);

        CaseDetailsPreloadable.preload();
        UserNewsDetailsPreloadable.preload();
    }

    return <>
        <Link to={RoutingPaths.calendar.route}>
            <CalendarOutlined className={styles.icon}/>
        </Link>
        <Dropdown
            placement="bottomRight"
            onVisibleChange={onSetVisible}
            trigger={['click']}
            visible={props.notificationsVisible}
            overlay={<NotificationsOverlay
                onNotificationClicked={() => props.setNotificationsVisible(false)}
                onClearClick={clearNotifications}
                isLoading={isLoading}
                notifications={notifications || []}/>}
        >
            <a onClick={e => e.preventDefault()}>
                <Badge count={notifications.filter(n => !n.isRead).length}
                       offset={[0, 5]}
                       color={"#B58845"}
                       style={{
                           fontSize: "14px",
                           fontWeight: "400",
                           left: "-26px",
                           width: "fit-content",
                           minWidth: "26px",
                           height: "22px",
                           lineHeight: "22px",
                           borderRadius: "16px"
                       }}>
                    {props.notificationsVisible ? <BellFilled className={classNames(styles.icon, styles.filledIcon)}/> :
                        <BellOutlined className={styles.icon}/>}
                </Badge>
            </a>
        </Dropdown>
        {contextHolder}
    </>;
}

const {TabPane} = Tabs;

function NotificationsOverlay(props: NotificationsOverlayProps) {
    const {notifications} = props;

    return <div className={styles.wrapper}>
        <div className={styles.title}>Powiadomienia</div>
        <Spin spinning={props.isLoading}>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Wszystkie" key="1">
                    <NotificationsList
                        onNotificationClicked={props.onNotificationClicked}
                        onClearClick={props.onClearClick}
                        notifications={notifications}/>
                </TabPane>
                <TabPane tab="Nieprzeczytane" key="2">
                    <NotificationsList
                        onNotificationClicked={props.onNotificationClicked}
                        onClearClick={props.onClearClick}
                        notifications={notifications.filter(n => !n.isRead)}/>
                </TabPane>
            </Tabs>
        </Spin>
    </div>;
}

function NotificationsList(props: NotificationListProps) {
    const {notifications} = props;
    const api = useInjection(ApiService);
    const dispatch = useDispatch();

    const [notificationsToShow, setNotificationsToShow] = useState<NotificationDto[]>([]);

    useEffect(() => {
        setNotificationsToShow(notifications?.slice(0, 30));
    }, [notifications]);

    useEffect(() => {
        api.getApi(ProductApi).apiProductMinimalGet().then(r => dispatch(setProducts(r.data)));
    }, [api, dispatch]);

    const onShowMore = () => {
        setNotificationsToShow(n =>
            [...n ?? [], ...notifications?.slice(n?.length, (n?.length || 0) + 30) ?? []]
        );
    }

    return <>
        <AsyncButton type="text" className={styles.markAsReadButton} onClick={props.onClearClick}>
            Oznacz wszystkie jako przeczytane
        </AsyncButton>

        <InfiniteScroll
            style={{overflow: "hidden"}}
            next={onShowMore}
            hasMore={notificationsToShow.length !== notifications?.length}
            loader={loadingSpin}
            dataLength={notificationsToShow.length}
        >
            <List
                dataSource={notificationsToShow}
                className={styles.notificationListWrapper}
                renderItem={n => <NotificationTile notification={n} onClicked={props.onNotificationClicked}/>}
            />
        </InfiniteScroll>
    </>;
}

function NotificationTile(props: { notification: NotificationDto, hideBadge?: boolean, onClicked?: () => void }) {
    const {notification} = props;
    const products = useSelector(getProducts);

    if (notification.news) {
        return <Link to={RoutingPaths.userNewsDetails.route.replace(":id", notification.news!.id!.toString())}
                     className={styles.tile} onClick={props.onClicked}>
            {!props.hideBadge && <Badge color={notification.isRead ? "transparent" : undefined} status="success"/>}
            <div className={styles.contentWrapper}>
                <Avatar style={{minWidth: 32}} size={32} icon={<ReadOutlined/>} className={styles.avatar}/>
                <div>
                    Opublikowano <strong>nowy artykuł</strong> w sekcji aktualności:
                    <Paragraph strong ellipsis={{rows: 4, expandable: true}}> {notification.news?.title}</Paragraph>
                    <div className={styles.time}>
                        {moment(notification.createdAt).locale("pl").fromNow()}
                    </div>
                </div>
            </div>
        </Link>;
    }

    const caseDetailsRoute = getCaseDetailsRouteForRole(notification.role!);

    if (notification.case) {
        return <Link
            to={caseDetailsRoute.replace(":id", notification.case.id!.toString())}
            className={styles.tile} onClick={props.onClicked}>
            {!props.hideBadge && <Badge color={notification.isRead ? "transparent" : undefined} status="success"/>}
            <div className={styles.contentWrapper}>
                <Avatar style={{minWidth: 32}} size={32} icon={<ReadOutlined/>} className={styles.avatar}/>
                <div>
                    Zostałeś przypisany do nowej sprawy
                    jako <strong>{notification.role && getSimpleRoleName(notification.role)}</strong>:
                    <br/>
                    <span>Rodzaj sprawy: {products?.find(p => p.id === notification.case.productId)?.name}</span>
                    <br/>
                    <strong>{notification.case.name}</strong>
                    <div className={styles.time}>
                        {moment(notification.createdAt).locale("pl").fromNow()}
                    </div>
                </div>
            </div>
        </Link>;
    }

    if (notification.caseMessage) {
        return <Link to={caseDetailsRoute.replace(":id", notification.caseMessage.caseId!.toString())}
                     className={styles.tile} onClick={props.onClicked}>
            {!props.hideBadge && <Badge color={notification.isRead ? "transparent" : undefined} status="success"/>}
            <div className={styles.contentWrapper}>
                <ProfilePicture size={32} profilePictureId={notification.caseMessage.createdBy?.profileImageId}/>
                <div>
                    <strong>{notification.caseMessage.createdBy?.firstName} {notification.caseMessage.createdBy?.lastName} </strong>
                    dodał(a) <strong>nowy komentarz</strong> w sprawie:
                    <Paragraph strong
                               ellipsis={{rows: 4, expandable: true}}> {notification.caseMessage.caseName}</Paragraph>
                    <div className={styles.time}>
                        {moment(notification.createdAt).locale("pl").fromNow()}
                    </div>
                </div>
            </div>
        </Link>;
    }

    const {caseUpdate} = notification;

    if (!caseUpdate) {
        return null;
    }

    return <Link to={caseDetailsRoute.replace(":id", caseUpdate!.caseId!.toString())} className={styles.tile}
                 onClick={props.onClicked}>
        {!props.hideBadge && <Badge color={notification.isRead ? "transparent" : undefined} status="success"/>}
        <div className={styles.contentWrapper}>
            <ProfilePicture size={32} profilePictureId={caseUpdate.createdBy?.profileImageId}/>
            <div>
                <strong>{caseUpdate!.createdBy?.firstName} {caseUpdate.createdBy?.lastName} </strong>
                {caseUpdate!.changedValueName === CaseHistoryValues.CaseCreated
                    ? <>zgłosił/a nową sprawę</>
                    : <>{getTimelineActionDescription(caseUpdate!)}
                        <strong> {caseUpdate.milestoneName ? caseUpdate.milestoneName : getTimelineFieldName(caseUpdate!.changedValueName)} </strong>
                        w sprawie
                    </>}
                <Paragraph strong ellipsis={{rows: 4, expandable: true}}> {caseUpdate!.caseName}</Paragraph>
                <div className={styles.time}>
                    {moment(notification.createdAt).locale("pl").fromNow()}
                </div>
            </div>
        </div>
    </Link>;
}

function getCaseDetailsRouteForRole(role: ApplicationUserRole) {
    switch (role) {
        case ApplicationUserRole.Client:
            return RoutingPaths.yourCaseDetails.route;
        case ApplicationUserRole.Manager:
            return RoutingPaths.managerClientCaseDetails.route;
        case ApplicationUserRole.ExpertEmployee:
            return RoutingPaths.expertCaseDetails.route;
        case ApplicationUserRole.Admin:
            return RoutingPaths.caseDetails.route;
        default:
            return RoutingPaths.yourCaseDetails.route;
    }
}

interface NotificationListProps {
    notifications: NotificationDto[],
    onClearClick: () => Promise<any>,
    onNotificationClicked: () => void
}

interface NotificationsOverlayProps extends NotificationListProps {
    isLoading: boolean
}
