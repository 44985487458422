import { createSlice } from "@reduxjs/toolkit";
import { ProfessionDto } from "../api";

 const initialState: { professions: ProfessionDto[] | null } = {
     professions: null
 };

 const professionSlice = createSlice({
     name: 'professionSlice',
     initialState,
     reducers: {
         setProfessions: (state, action) => {
             state.professions = action.payload;
         }
     }
 });

 // these methods only modify the state
 export const { setProfessions } = professionSlice.actions;

 // these methods only read the state
 export const getProfessions = (state: any) => state.profession.professions as ProfessionDto[] | null;

 export default professionSlice.reducer;