import { createSlice } from "@reduxjs/toolkit";
import { OrganisationDto, ProductDto } from "../api";

 const initialState: { organisations: OrganisationDto[] | null } = {
     organisations: null
 };

 const organisationSlice = createSlice({
     name: 'organisationSlice',
     initialState,
     reducers: {
         setOrganisations: (state, action) => {
             state.organisations = action.payload;
         }
     }
 });

 // these methods only modify the state
 export const { setOrganisations } = organisationSlice.actions;

 // these methods only read the state
 export const getOrganisations = (state: any) => state.organisation.organisations as OrganisationDto[] | null;

 export default organisationSlice.reducer;